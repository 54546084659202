import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts"
import Grid from "../components/grids/base"
import HeroInner from "../components/sections/hero/inner"
import { SubTitle, Title } from "../components/atoms/titles"
import { Html } from "../components/atoms/text"
import SectionFaq from "../components/sections/faq"

const IndexPage = ({ data: { node } }) => {

  //console.log(node)
  return (
    <Layout>
      <Hero data={node.relationships.hero} />
      <Section1 data={node.relationships.section1} />
      <Section2 data={node.relationships.faq} title={node.faq_title} />
      <Section1 data={node.relationships.section3} />

    </Layout>
  )
}


const Hero = ({ data }) => {

  const { title, subtitle } = data
  const url = data.relationships.image.localFile.publicURL

  const action1 = data.relationships.actions[0]
  const action2 = data.relationships.actions[1]

  return (
    <HeroInner
      title={title}
      style={{ backgroundImage: `url("${url}")` }}
      description={subtitle}
      action1={action1}
      action2={action2}
    />
  )
}

const Section1 = ({ data }) => {

  //console.log('section1', data)

  const { title, subtitle } = data

  const html = data.description
    .map(item => (item.processed))
    .map((item, index) => (
      <Html key={index} className="text-[25px] user-content">{item}</Html>
    ))

  return (

    <Grid
      className="grid-cols-[1fr_2fr] gap-0">

      <div className="">
        <img src={data.relationships.image.localFile.publicURL} alt={data.image.alt} />
      </div>

      <div className=" p-24">
        <SubTitle className="text-[50px] font-light">{title}</SubTitle>
        <Title className="text-[55px] font-bold">{subtitle}</Title>
        {html}
      </div>

    </Grid>

  )
}

const Section2 = ({ data, title }) => {


  const items = data.map((item) => ({
    question: <Title className="text-[35px] font-bold text-[#F2F2F2] cursor-pointer">{item.title}</Title>,
    answer: <Html className="bg-[#F2F2F2] p-8 text-black mb-2 text-[25px]">{item.description.processed}</Html>
  }))

  return (

    <SectionFaq
      title={title}
      items={items}
      className=" bg-dark text-white px-48 py-14 "
      classNameTitle="text-[50px] font-light mb-12"
    />

  )

}


export default IndexPage

export const query = graphql`
  query service($id: String!) {
    node: nodeSevice(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      faq_title: field_section_title
      path{
        alias
      }
      relationships {
        hero: field_hero {
          ...HeroContent
        }
        section1: field_paragraph_block {
          ...BlockContent
        }
        faq: field_faq {
          title: field_title
          description: field_description {
            processed
          }
        }
        section3: field_block2 {
          ...BlockContent
        }
      }
    }
  }
`
