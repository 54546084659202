import React, { useState } from "react"
import SectionBase from "./base";
import { Title } from "../atoms/titles";
//import { Description, Html } from "../atoms/text";
//import { Link } from "gatsby"

const SectionFaq = ({
  title = '',
  items = [],
  className = "",
  classNameTitle = "text-[60px] font-bold",
  style = {},
  children
}) => {

  const questions = items.map((item, index) => (
    <FaqItem key={index} {...item} />
  ))

  return (
    <SectionBase className={className} style={style}>

      <Title className={classNameTitle}>{title}</Title>
      <div>
        {questions}
      </div>
      {children}

    </SectionBase>

  )
}

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div>
      <button onClick={() => { setIsOpen(!isOpen) }} >{question}</button>
      <div className={" " + (isOpen ? 'block' : 'hidden')}>{answer}</div>
    </div>
  )
}

const SectionFaqExample = () => {
  return (
    <SectionFaq
      className="p-24"
      title="שאלות נפוצות"
      //description="בל הצעות מיוחדות, חדשות מוצר בלעדיות ומידע על אירועים ישירות לתיבת הדואר הנכנס שלך"
      items={[
        { question: "why", answer: "because" },
        { question: "where ", answer: "there" },
      ]}
    />
  )
}

export default SectionFaq;
export { FaqItem, SectionFaqExample };
