import * as React from "react"
import { Link } from "gatsby"
//import { pipesToSpan } from "../util/common"
//import Image from "./Image"
import { Title } from "../../atoms/titles"
import { Description } from "../../atoms/text"


// full width, with image bg or video in the background
const HeroBase = ({
  className = "pt-32 pb-12 lg:pt-48 lg:pb-32",
  style = {},
  children
}) => {
  
  if ( style.backgroundImage && style.backgroundImage.startsWith("url") ) {
    style.backgroundImage = 'linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ' + style.backgroundImage
  }

  return (
    <div
      className={'bg-no-repeat bg-center bg-cover bg-bottom ' + className}
      style={style}
    >
      {children}
    </div>
  )
}

const HeroBaseExample = () => {

  return (
    <HeroBase className=' h-[950px] text-white' style={{ backgroundImage: 'url("https://picsum.photos/1980/950")' }}>
      <Title className="text-[80px] font-bold">טקסט שיווקי</Title>
      <Description>
        הועניב היושבב שערש שמחויט - שלושע ותלברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאחו נמרגי שהכים תוק, הדש שנרא התידם הכייר וק.
      </Description>
      <Link to={'/'} className="button-white">כפתור קריאה לפעולה</Link>
      <Link to={'/'} className="button-primary">TEST RIDE</Link>
    </HeroBase >
  )
}


export default HeroBase
export { HeroBaseExample }

