import * as React from "react"
import { Link } from "gatsby"
//import PropTypes from 'prop-types'
//import { pipesToSpan } from "../util/common"
//import Image from "./Image"
import { Title } from "../../atoms/titles"
import { Description } from "../../atoms/text"
import HeroBase from "./base"


// full width, with image bg or video in the background
const HeroInner = ({
  title = '',
  description = '',
  action1,
  action2,
  className = "h-[300px] lg:h-[1050px] text-white pt-42  lg:pt-60",
  style = {},
  children
}) => {

  if ( style.backgroundImage && style.backgroundImage.startsWith("url") ) {
    style.backgroundImage = 'linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), ' + style.backgroundImage
  }
  
  //style.backgroundPosition = 'background-position: bottom'
  const b1 = action1 ? <Link to={action1.url} className="underline lg:no-underline font-bold lg:font-normal px-3 lg:button-primary">{action1.label}</Link> : false
  const b2 = action2 ? <Link to={action2.url} className="button-primary">{action2.label}</Link> : false

  return (
    <>
    <HeroBase
      className={'bg-no-repeat  bg-cover  bg-[center_bottom] ' + className}
      style={style}
    >
      <div className="container hidden lg:block">
        
        <Title className="text-[40px] lg:text-[70px] font-bold leading-10  lg:leading-normal ">{title}</Title>
        <Description className="text-xl lg:text-2xl lg:font-light mb-4">{description}</Description>
        {b1}
        {/* <div className="absolute left-0">{b2}</div> */}
        {children}
      </div>

    </HeroBase>

    <div className="container lg:hidden py-8 bg-dark text-white text-center">
        
        <Title className="text-3xl  font-bold ">{title}</Title>
        <Description className="text-lg lg:text-2xl lg:font-light mb-4">{description}</Description>
        {b1}
        {/* <div className="absolute left-0">{b2}</div> */}
        {children}
      </div>
    </>
  )
}


HeroInner.defaultProps = {
  action2: {
    url: '/test-ride',
    label: 'TEST RIDE',
  }
};

const HeroInnerExample = () => {

  return (
    <HeroInner
      className=' h-[950px] text-white pt-60 pb-30'
      style={{ backgroundImage: 'url("https://picsum.photos/1980/950")' }}
      title="כותרת"
      description="לברו חשלו שעותלשך וחאית נובש ערששף. זותה מנק הבקיץ אפאח דלאמת יבש, כאנה ניצאח"
      action1={{
        'url': '/about',
        'label': 'הרשמה'
      }}
    >
      <div>notice</div>
    </HeroInner>

  )
}


export default HeroInner
export { HeroInnerExample }

