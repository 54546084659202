import React from "react"

const GridBase = ({ className = 'grid-cols-2', children }) => {
  return (
    <div className={'grid ' + className}>
      {children}
    </div>
  )
}


export default GridBase